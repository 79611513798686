import "./App.scss"
import { RecoilRoot } from "recoil"
import { LittaThemeProvider } from "@litta/ui"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import Router from "pages/Routes"

const queryClient = new QueryClient()

const App = () => {
  return (
    <RecoilRoot>
      <div className="App">
        <LittaThemeProvider>
          <QueryClientProvider client={queryClient}>
            <Router />
          </QueryClientProvider>
        </LittaThemeProvider>
      </div>
    </RecoilRoot>
  )
}

export default App
