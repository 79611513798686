import styled from "@emotion/styled"

export const PageWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow-y: overlay;
  min-height: 100vh;
  width: 100%;
  ::-webkit-scrollbar {
    position: absolute;
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.greyscale[200]};
  }
  ::-webkit-scrollbar-thumb {
    border: 6px solid rgba(0, 0, 0, 0);
    border-radius: 100px;
    background-clip: padding-box;
    background-color: ${(props) => props.theme?.colors?.greyscale?.[300]};
  }
`

const PageContainer = styled.div`
  display: flex;
  height: 100%;
  max-width: 600px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
`

interface DefaultLayoutProps {
  children: React.ReactNode
  header?: React.ReactNode
  footer?: React.ReactNode
}

export const DefaultLayout = ({
  children,
  header,
  footer
}: DefaultLayoutProps) => {
  return (
    <PageWrapper>
      {header}
      <PageContainer className="page-container">{children}</PageContainer>
      {footer}
    </PageWrapper>
  )
}
