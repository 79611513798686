export interface HeaderProps {
  title: string
  imgSrc: string
}
export const Header = ({ title, imgSrc }: HeaderProps) => {
  return (
    <header className="header-container" style={{ padding: "16px" }}>
      <div className="header-content">
        <img src={imgSrc} alt="logo" height={50} />
        <h1>{title}</h1>
      </div>
    </header>
  )
}

export default Header
