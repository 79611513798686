import { PageWrapper } from "../components/PageWrapper"

export const RootPage = () => {
  return (
    <PageWrapper>
      <h1>Oops</h1>
      <p>Something went wrong</p>
    </PageWrapper>
  )
}

export default RootPage
