// Libraries
import { BrowserRouter, Routes, Route } from "react-router-dom"

// Pages
import FormsPage from "pages/FormsPage"
import UpdateFormPage from "pages/UpdatePage"
import OfferPage from "pages/OfferPage"
import RootPage from "pages/RootPage"

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<RootPage />} />
      <Route path="/:slug" element={<FormsPage />} />
      <Route path="/:slug/:bookingId" element={<UpdateFormPage />} />
      <Route path="/:slug/offer/:bookingId" element={<OfferPage />} />
    </Routes>
  </BrowserRouter>
)

export default Router
