import styled from "@emotion/styled"

const StyledFooter = styled.footer`
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: baseline;
  margin-top: auto;
`

export interface FooterProps {
  text: string
}

export const Footer = ({ text }: FooterProps) => {
  return (
    <StyledFooter className="footer-container">
      <div className="footer-content">
        <p>{text}</p>
      </div>
    </StyledFooter>
  )
}

export default Footer
