import { UpdateForm } from "@litta/ui"
import { useParams } from "react-router-dom"
import { useGetFormDataBySlug } from "@litta/hooks"
import { Header } from "../components/Header"
import { DefaultLayout } from "components/layout/DefaultLayout"
import Footer from "components/Footer"

const UpdateFormPage = () => {
  const params = useParams()
  const { slug, bookingId } = params

  const { formQuery, fieldsQuery }: any = useGetFormDataBySlug(slug)

  if (formQuery.error || fieldsQuery.error) {
    formQuery.error && console.error(formQuery.error)
    fieldsQuery.error && console.error(fieldsQuery.error)

    const errors = [formQuery.error, fieldsQuery.error].filter(Boolean)
    if (errors) return <pre>{JSON.stringify(errors, null, 2)}</pre>
  }

  if (formQuery.isLoading) {
    return null
  }

  if (formQuery.isSuccess && formQuery.data && slug)
    return (
      <DefaultLayout
        header={
          <Header
            title={formQuery.data.form_title}
            imgSrc={formQuery.data.logo_v2?.standard_url ?? ""}
          />
        }
        footer={<Footer text={formQuery.data.footer ?? ""} />}
      >
        {fieldsQuery.isLoading && <p>Fetching form...</p>}

        {fieldsQuery.isSuccess && (
          <UpdateForm
            slug={slug}
            formNameSlug={formQuery.data.name}
            bookingId={bookingId}
          />
        )}
      </DefaultLayout>
    )

  return <div>Something went wrong</div>
}

export default UpdateFormPage
