import { BaseForm } from "@litta/ui"
import { useParams } from "react-router-dom"
import { useGetFormDataBySlug } from "@litta/hooks"
import { Header } from "../components/Header"
import { DefaultLayout } from "components/layout/DefaultLayout"
import MetaTags from "react-meta-tags"
import Footer from "components/Footer"

export const FormsPage = () => {
  const params = useParams()
  const { slug } = params

  const { formQuery, fieldsQuery }: any = useGetFormDataBySlug(slug)

  if (formQuery.error || fieldsQuery.error) {
    formQuery.error && console.error(formQuery.error)
    fieldsQuery.error && console.error(fieldsQuery.error)

    const errors = [formQuery.error, fieldsQuery.error].filter(Boolean)
    if (errors) return <pre>{JSON.stringify(errors, null, 2)}</pre>
  }

  if (formQuery.isLoading) {
    return null
  }

  if (formQuery.isSuccess && formQuery.data && slug)
    return (
      <DefaultLayout
        header={
          <Header
            title={formQuery.data.form_title}
            imgSrc={formQuery.data.logo_v2?.standard_url ?? ""}
          />
        }
        footer={<Footer text={formQuery.data.footer ?? ""} />}
      >
        <MetaTags>
          <title>{formQuery.data["seo-title"] || formQuery.data.title}</title>
          <meta
            name="description"
            content={formQuery.data["seo-description"] || formQuery.data.title}
          />
        </MetaTags>
        {fieldsQuery.isLoading && <p>Fetching form...</p>}
        {fieldsQuery.isSuccess && (
          <BaseForm slug={slug} formNameSlug={formQuery.data.name} />
        )}
      </DefaultLayout>
    )

  return <div>Something went wrong</div>
}

export default FormsPage
